<template>
  <MobileMain v-if="isMobile" />
  <desktop-main v-else />
</template>

<script>
import {mapGetters} from "vuex";
import MobileMain from "./MobileMain.vue";
import DesktopMain from "./desktopMain.vue";
const moment = require('moment-jalaali')

export default {
  name: "Main",
  components: {DesktopMain, MobileMain},
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    })
  },
  mounted() {
    moment.loadPersian({dialect: 'persian-modern'})
  }
}
</script>

<style scoped>

</style>
